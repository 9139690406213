
import { AdvertiserInfo } from '@point/utility-classes';
import Vue from 'vue';
import { SiteImpactCampaigns, SiteImpact } from '../../../../store/modules/linearadmin/types/siteimpact';

type SiteImpactConnectorCampaign = {
  accountName: string;
  connections: {
    id: string;
    name: string;
    accountName: string;
    enabled: boolean;
    connection_date: string;
  }[];
};

export default Vue.extend({
  name: 'linearAdminSiteImpactAds',
  components: {
    Connection: () => import('./connection.vue'),
    SelectedAccounts: () => import('./selectedAccounts.vue'),
  },
  props: ['editRights'],
  data: (): {
    searchQuery: string;
    loading: boolean;
    connectionData: SiteImpactConnectorCampaign[] | null;
    fadeSave: boolean;
    searchResults: SiteImpact | null;
    savedResults: object;
    enabledAccount: boolean;
    minSearchLength: number;
    saveSiteImpactCampaignsError: string;
  } => ({
    searchQuery: '',
    loading: false,
    connectionData: null,
    fadeSave: true,
    searchResults: null,
    savedResults: {},
    enabledAccount: false,
    minSearchLength: 3,
    saveSiteImpactCampaignsError: ''
  }),
  created() {
    if (this.enabled) {
      this.getConnections();
    }
  },
  computed: {
    isLoading(): boolean {
      return this.loading || this.$store.state.agencyAdmin.loading || this.$store.state.linearAdmin.loading;
    },
    searchHint(): string {
      const searchLength = this.searchQuery?.length;
      if (searchLength < this.minSearchLength) {
        if (searchLength === 0) return `Type ${this.minSearchLength} or more characters`;
        else return `Type ${this.minSearchLength - searchLength} more characters`;
      }
      return '';
    },
    advertiserInfo(): AdvertiserInfo | null {
      return this.$store.state?.advertiser?.advertiserInfo?.data || null;
    },
    enabled(): boolean {
      return (
        this.advertiserInfo.AgencyPartner === 'Sinclair' ||
        this.advertiserInfo.AgencyPartner === 'LPP' ||
        this.advertiserInfo.AgencyPartner === 'PitchQA' ||
        this.advertiserInfo.AgencyPartner === 'Demo' ||
        this.advertiserInfo.AgencyPartner === 'WG_Communications_Group' ||
        this.advertiserInfo.AgencyPartner === 'Bally_Sports'
      );
    },
    saveButtonStyle(): object | null {
      if (this.fadeSave) return { opacity: 0.5 };
      return null;
    },
    campaignIds(): string[] {
      function getCampaigns(all, campaign) {
        all.push(campaign.id);
        return all;
      }

      const campaignIds = this.connectionData.reduce((all, c) => {
        const connections = c.connections.reduce(getCampaigns, []);
        all.push(...connections);
        return all;
      }, []);
      return campaignIds;
    },
  },
  methods: {
    async siteImpactSearch(search: string): Promise<void> {
      this.loading = true;

      const results = await this.$store.dispatch('linearAdmin/getSiteImpact', {
        advertiserId: this.$route.query.ppid,
        search: search,
      });

      const connected = new Set(this.campaignIds);

      results.map(a =>
        a.campaigns.forEach(campaign => {
          campaign.status = connected.has(campaign.campaignId) ? 'checked' : 'unchecked';
        }),
      );
      this.searchResults = results;
      if (this.searchResults?.length > 0) {
        // store results based on search term
        this.savedResults[search] = JSON.stringify(this.searchResults);
      }
      this.loading = false;
    },
    onSearch(): void {
      // clear focus timer
      clearTimeout(this.searchTimer);
      if (this.searchQuery.length >= this.minSearchLength || this.searchQuery === '') {
        // set timeout to allow for user input.
        this.searchTimer = setTimeout(async () => {
          this.searchLoading = true;
          this.searchResults = null; // reset results
          // check for saved results.
          if (this.savedResults.hasOwnProperty(this.searchQuery)) {
            try {
              // try to get the results
              this.searchResults = JSON.parse(this.savedResults[this.searchQuery]);
              this.searchLoading = false;
            } catch (err) {
              // on error clear that store item and search again.
              delete this.savedResults[this.searchQuery];
              this.siteImpactSearch(this.searchQuery);
            }
          } else {
            delete this.savedResults[this.searchQuery];
            // if we can't find the cached result, search for them.
            this.siteImpactSearch(this.searchQuery);
          }
        }, 600);
      } else if (this.searchQuery.length < this.minSearchLength) {
        this.searchResults = null; // reset results
        return;
      }
    },
    async getConnections(): Promise<void> {
      this.loading = true;
      try {
        const data = await this.$store.dispatch('linearAdmin/getSiteImpactConnector', {
          id: this.$route.query.ppid,
        });

        this.enabledAccount = data?.length ? data.every(item => item.enabled === true) : false;

        const summary = {};
        data.forEach(item => {
          const { accountName, id, name, enabled, connection_date: connectionDate } = item;

          if (!summary[accountName]) {
            summary[accountName] = {
              accountName: accountName,
              connections: [],
            };
          }
          summary[accountName].connections.push({ id, name, enabled, connectionDate });
        });

        const result = Object.values(summary);
        this.connectionData = result;
        this.siteImpactSearch(this.search);
      } catch (exp) {
        // eslint-disable-next-line no-console
        console.error('getConnections error', exp);
      } finally {
        this.loading = false;
      }
    },
    async saveSiteImpactCampaigns(): Promise<void> {
      if (!this.fadeSave) {
        this.loading = true;
        this.saveSiteImpactCampaignsError = '';
        try {
          await this.$store.dispatch('linearAdmin/addSiteImpactConnector', {
            id: this.$route.query.ppid,
            campaignIds: this.campaignIds,
          });
          this.getConnections();
        } catch (error) {
          this.saveSiteImpactCampaignsError = error.message;
          // eslint-disable-next-line no-console
          console.error(error);
        } finally {
          this.loading = false;
          this.fadeSave = true;
        }
      } else return;
    },
    removeCampaign(campaignId: string): void {
      let tmpLinking = JSON.parse(JSON.stringify(this.connectionData));
      try {
        tmpLinking = this.connectionData.reduce((allAccounts, a) => {
          if (!a.connections.length) {
            return;
          }
          a.connections = a.connections.filter(c => c.id !== campaignId);

          this.searchResults.map(a =>
            a.campaigns.find(campaign => {
              if (campaign.campaignId === campaignId) {
                campaign.status = 'checked' ? 'unchecked' : 'checked';
              }
            }),
          );
          allAccounts.push(a);
          return allAccounts;
        }, []);
        this.fadeSave = false;
        this.$set(this, 'connectionData', tmpLinking);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('siteimpact removeCampaign', 'failed to remove campaign', e);
      }
    },
    addCampaign(connection: SiteImpactCampaigns, accountName: string): void {
      try {
        const tmpLinking = JSON.parse(JSON.stringify(this.connectionData));

        const matchingAccount = tmpLinking?.find(a => a.accountName === accountName);

        if (matchingAccount) {
          const matchingCampaign = matchingAccount.connections.find(c => c.id === connection.campaignId);

          if (matchingCampaign) {
            matchingAccount.connections = matchingAccount.connections.filter(c => c.id !== connection.campaignId);
          } else {
            matchingAccount.connections.push({
              id: connection.campaignId,
              name: connection.campaignName,
              enabled: true,
            });
          }
        } else {
          tmpLinking.push({
            accountName,
            connections: [
              {
                id: connection.campaignId,
                name: connection.campaignName,
                enabled: true,
              },
            ],
          });
        }
        this.fadeSave = false;
        this.$set(this, 'connectionData', tmpLinking);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('siteimpact addCampaign', 'failed to add campaign');
      }
    },
  },
});
